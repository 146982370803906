import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { isEmpty, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import User from '../../data/entities/user';
import AuthEntry from './_auth_entry';
import AuthMissingUserDetails from './_auth_missing_user_details';
import AuthOTP from './_auth_otp';
import t from '../../t';

function Auth({
  afterAuthComponent,
  inModal,
  afterAuthMode = 'complete',
  afterAuth,
}) {
  const { userStore } = useStore();
  const [authInitialising, setAuthInitialising] = useState(false);
  const AfterAuthComponent = afterAuthComponent;

  useEffect(() => {
    if (
      (!userStore.userSignedIn && isEmpty(userStore.newUser)) ||
      !userStore.newUser?.persisted
    ) {
      userStore.update({
        newUser: new User({}, userStore),
        authMode: 'signin',
      });
    }
    if (
      userStore.userSignedIn &&
      !isEmpty(userStore.currentUser?.fullName?.trim())
    ) {
      userStore.update({ authMode: afterAuthMode });
    }
    setAuthInitialising(true);
  }, []);

  const afterAuthenticationComplete = () => {
    userStore.update({ authMode: afterAuthMode });
    if (isFunction(afterAuth)) afterAuth();
  };

  if (!authInitialising) return null;

  const renderFormBody = mode => {
    console.log(`renderFormBody ${mode}`, mode);
    //  not signed in, show the auth entry form or the OTP form

    if (!userStore.userSignedIn) {
      return userStore.newUser?.persisted ? (
        <AuthOTP
          onSuccess={() => {
            if (!isEmpty(userStore.currentUser?.fullName?.trim()))
              afterAuthenticationComplete();
          }}
        />
      ) : (
        <AuthEntry />
      );
    }

    //  signed in , check that the user details are complete, if not prompt
    if (isEmpty(userStore.currentUser?.fullName?.trim())) {
      return <AuthMissingUserDetails onUpdate={afterAuthenticationComplete} />;
    }

    if (!isEmpty(AfterAuthComponent)) return <AfterAuthComponent />;
  };

  const componentToShow = renderFormBody(userStore.authMode);

  if (!componentToShow) return null;

  return (
    <Card>
      {!inModal && (
        <CardHeader>
          <h4 className="p-0 m-0">{t(`auth.authenticate.title`)}</h4>
          <p className="x-small text-muted p-0 m-0">
            {t(`auth.authenticate.description`)}
          </p>
        </CardHeader>
      )}
      <CardBody>{componentToShow}</CardBody>
    </Card>
  );
}

Auth.propTypes = {
  afterAuth: PropTypes.func,
  afterAuthComponent: PropTypes.node,
  inModal: PropTypes.bool,
  afterAuthMode: PropTypes.string,
};

export default observer(Auth);
