import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStore from '../../data/store';
import Auth from './_auth';
import t from '../../t';

function AuthModal({ isOpen, mode }) {
  const { userStore } = useStore();

  useEffect(() => {
    userStore.update({ authMode: mode });
  }, []);

  const toggle = () => {
    userStore.update({ showAuthModal: false });
  };

  return (
    <Modal backdrop="static" isOpen={userStore.showAuthModal} size="md">
      <ModalHeader toggle={toggle}>
        <h4 className=" p-0 pb-3 m-0">{t(`auth.authenticate.title`)}</h4>
        <p className="x-small text-muted p-0 m-0">
          {t(`auth.authenticate.description`)}
        </p>
      </ModalHeader>

      <ModalBody className="p-0">
        <Auth inModal afterAuth={toggle} />
      </ModalBody>
    </Modal>
  );
}

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};
export default observer(AuthModal);
