import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Button, FormGroup, Input, Label } from 'reactstrap';
import useStore from '../../data/store';

function AuthEntry() {
  const { userStore } = useStore();
  const user = userStore.newUser;

  useEffect(() => {
    userStore.update({
      authMode: 'signin',
    });
  }, []);

  const handleUserCreate = e => {
    e.preventDefault();
    user.create();
  };

  const handleChange = e => {
    user.update({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleUserCreate}>
      <FormGroup>
        <Label>Email</Label>
        <Input value={user.email} onChange={handleChange} name="email" />
      </FormGroup>
      <Button type="submit" color="primary" className="mt-3">
        Submit
      </Button>
    </form>
  );
}
export default observer(AuthEntry);
