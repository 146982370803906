import {
  Button,
  Col,
  Form as ReactForm,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { find, includes, isEmpty, isFunction } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Select from 'react-select';
import t from '../../t';
import VideoUploaderWithUrl from '../shared/_video_uploader_with_url';
import Announcement from '../../data/entities/announcement';
import useStore from '../../data/store';
import { filterSelectStyles } from '../../helpers/sidebar_helpers';
import { basicEditorToolbar } from '../../helpers/shared_helpers';
import RichEditor from '../modules/rich_text_editor';

const defaultBody = `
  <div>
    <h4><b>What have we been working on?</b></h4>
    <blockquote>[response]</blockquote>
    <h4><b>What's worth celebrating?</b></h4>
    <blockquote>[response]</blockquote>
    <h4><b>What have we learnt?</b></h4>
    <blockquote>[response]</blockquote>
    <h4><b>What are we working on next?</b></h4>
    <blockquote>[response]</blockquote>
    <h4><b>What help do we need?</b></h4>
    <blockquote>[response]</blockquote>
  </div>
`;

const AnnouncementBox = ({
  project,
  announceableId,
  announceableType,
  projectHelperIds,
  onAnnouncement,
  announcementType,
  disabled,
  icon,
  record,
  buttonTitle,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { announcementStore } = useStore();

  const [announcement, setAnnouncement] = useState(null);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    if (open) {
      setAnnouncement(
        new Announcement(
          record?.json || {
            announceable_type: announceableType || 'Helpable',
            announceable_id: announceableId || project?.id,
            subject: '',
            body: '',
            announcement_type: announcementType,
            use_personal_smtp: false,
          },
          announcementStore
        )
      );

      setIsPublished(record?.status === 'published');
    } else {
      setAnnouncement(null);
      setIsPublished(false);
    }
  }, [open]);

  const saveFormAndExit = (exit = true) => {
    setLoading(true);

    announcement.save(projectHelperIds).then(res => {
      if (!isEmpty(res)) {
        if (isFunction(onAnnouncement)) onAnnouncement(res);

        if (exit) setOpen(false);
      }
      setLoading(false);
    });
  };

  const handleChange = e => {
    announcement.update({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (loading) return;
    saveFormAndExit();
  };

  const handleAnnounceableTypeChange = selectedOption => {
    const isProjectStatus = selectedOption.value === 'ProjectStatus';
    announcement.update({
      announcementType:
        selectedOption.value === 'ProjectStatus' ? 'status' : 'in_app',
      announceableType:
        selectedOption.value === 'ProjectStatus'
          ? 'Helpable'
          : selectedOption.value,
      body: isProjectStatus ? defaultBody : '',
      announceableId: includes(
        ['Helpable', 'ProjectStatus'],
        selectedOption.value
      )
        ? project.id
        : '',
    });
  };

  const toggle = () => setOpen(!open);
  const getAnnounceableOptions = () => {
    const opts = [];

    if (project.canManage) {
      opts.push({
        label: t('announcements.types.general'),
        value: 'Helpable',
      });
      opts.push({
        label: t('announcements.types.project_status'),
        value: 'ProjectStatus',
      });
    }

    if (project.canManage || project.canManageNeeds) {
      opts.push({
        label: t('announcements.types.category'),
        value: 'Category',
      });
    }

    return opts;
  };

  const title =
    buttonTitle ||
    (announcementType === 'direct'
      ? t('announcements.titles.send_message')
      : t('announcements.titles.make_announcement'));

  const isDirect = announcement?.announcementType === 'direct';
  const showAnnounceableTypeField = !isDirect && isEmpty(announceableType);
  const announceableTypeOptions = showAnnounceableTypeField
    ? getAnnounceableOptions()
    : [];

  const getAnnouncementTypeValue = () =>
    find(announceableTypeOptions, {
      value:
        announcement.announcementType === 'status'
          ? 'ProjectStatus'
          : announcement.announceableType,
    }) || null;

  const changeStatus = () => {
    announcement.update({ status: isPublished ? 'draft' : 'published' });
    setIsPublished(!isPublished);
    saveFormAndExit(false);
  };
  if (!isEmpty(announcement)) console.log('AnnouncementBox', announcement);

  return (
    <>
      <Button
        id="announcement-popover"
        size={icon ? 'sm' : 'xs'}
        color="primary"
        onClick={toggle}
        disabled={disabled}
        className={classNames({ 'rounded-circle': icon })}
      >
        {icon && <FontAwesomeIcon icon="plus" />}
        {!icon && (loading ? t('common.saving') : title)}
      </Button>
      <Modal size="xl" toggle={toggle} isOpen={open} backdrop="static" centered>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {!isEmpty(announcement) && (
            <ReactForm className="bonus-form" onSubmit={handleSubmit}>
              <Row>
                <Col lg={isDirect ? 12 : 6}>
                  {showAnnounceableTypeField && (
                    <>
                      <FormGroup>
                        <Label>
                          {t('announcements.labels.announcement_type')}
                        </Label>
                        <Select
                          styles={filterSelectStyles}
                          options={announceableTypeOptions}
                          value={getAnnouncementTypeValue()}
                          onChange={handleAnnounceableTypeChange}
                        />
                      </FormGroup>
                      {announcement.announceableType === 'Category' && (
                        <FormGroup>
                          <Label>
                            {t('announcements.labels.project_need')}
                          </Label>
                          <Select
                            styles={filterSelectStyles}
                            options={project.needsAsSelectOptions}
                            value={
                              find(project.needsAsSelectOptions, {
                                value: announcement.announceableId,
                              }) || null
                            }
                            onChange={selectedOption => {
                              announcement.update({
                                announceableId: selectedOption.value,
                              });
                            }}
                          />
                        </FormGroup>
                      )}
                    </>
                  )}
                  <FormGroup>
                    <Label>{t('announcements.labels.subject')}</Label>
                    <Input
                      className="small mr-2 h-25"
                      name="subject"
                      required
                      value={announcement.subject}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{t('announcements.labels.body')}</Label>
                    <RichEditor
                      onChange={m =>
                        announcement.update({
                          body: m,
                        })
                      }
                      value={announcement.body}
                      toolbar={basicEditorToolbar}
                      className="text-cta-editor editor-sm"
                    />
                  </FormGroup>
                  {isDirect && project.settings.enable_smtp && (
                    <FormGroup check>
                      <Input
                        id="use_personal_smtp"
                        type="checkbox"
                        name="use_personal_smtp"
                        checked={announcement.use_personal_smtp}
                        onChange={() =>
                          handleChange({
                            target: {
                              name: 'use_personal_smtp',
                              value: !announcement.use_personal_smtp,
                            },
                          })
                        }
                      />
                      <Label for="use_personal_smtp">
                        {t('announcements.labels.use_personal_smtp')}
                      </Label>
                    </FormGroup>
                  )}
                </Col>
                {!isDirect && (
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        {t('announcements.labels.announcement_video')}
                      </Label>
                    </FormGroup>

                    <VideoUploaderWithUrl
                      attachable={announcement}
                      withThumbnails
                      removeAttachment={() =>
                        announcement.update({
                          file: null,
                          thumbnails: [],
                          thumbnail: '',
                        })
                      }
                    />
                  </Col>
                )}
              </Row>
              {isDirect && (
                <div className="text-muted small-12 mt-1 mb-2">
                  {t('announcements.labels.recipients', {
                    count: projectHelperIds.length,
                  })}
                </div>
              )}
              <div className="text-right mt-3">
                {!isDirect && !isPublished && announcement.persisted && (
                  <Button
                    className="mr-3"
                    color="info"
                    size="sm"
                    onClick={changeStatus}
                  >
                    {t('common.publish')}
                  </Button>
                )}
                <Button
                  disabled={loading}
                  color="primary"
                  size="sm"
                  type="submit"
                >
                  {loading
                    ? t('common.saving')
                    : isPublished
                    ? t('common.update')
                    : t('common.save_draft')}
                </Button>
              </div>
            </ReactForm>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

AnnouncementBox.defaultProps = {
  projectHelperIds: [],
  announcementType: 'in_app',
  announceableType: '',
  announceableId: '',
  icon: false,
};

AnnouncementBox.propTypes = {
  project: PropTypes.object.isRequired,
  announceableType: PropTypes.string,
  announcementType: PropTypes.string,
  announceableId: PropTypes.string,
  projectHelperIds: PropTypes.instanceOf(Array),
  onAnnouncement: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  record: PropTypes.instanceOf(Object),
  buttonTitle: PropTypes.string,
};

export default observer(AnnouncementBox);
