import React, { useState } from 'react';

import { observer } from 'mobx-react';
import {
  Badge,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty, map, tail } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import AskForm from './Form';
import useStore from '../../data/store';

const AskActions = ({ ask }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { appStore } = useStore();
  const toggle = () => setIsOpen(!isOpen);

  const actions = [];
  const nextBestActions = [];

  const editAsk = () => {
    const editableAsk = {
      name: 'asks',
      component: AskForm,
      modalTitle: 'Edit Ask',
      record: ask,
    };
    appStore.initiateCreatable(editableAsk);
  };

  const deleteAsk = () => {
    confirmAlert({
      title: 'Confirm Delete Ask',
      message:
        'This ask will be deleted and all record of it will disappear. It is not reversible. Are you sure?',
      buttons: [
        {
          label: 'Delete',
          onClick: () => ask.delete(),
        },
        { label: 'Cancel' },
      ],
    });
  };

  if (!ask.amIHelping && !ask.isExpired && ask.startable) {
    nextBestActions.push({
      label: 'Help with this',
      color: 'primary',
      click: () => ask.join(),
    });
  }

  if (ask.myHelp?.helping || ask.myHelp?.rejected) {
    nextBestActions.push({
      label: ask.myHelp?.rejected ? 'Restart' : 'Start Work',
      click: () => {
        if (ask.startable || ask.myHelp?.rejected) return ask.myHelp.start();

        ask.notifyError(
          "Maximum helpers reached. You cannot start on this ask just yet. If another helper stops working on it , we'll let you know"
        );
      },
    });
  }
  if (ask.myHelp?.inProgress) {
    nextBestActions.push({
      label: 'Send to review',
      click: () => ask.myHelp.review(),
    });
    nextBestActions.push({
      label: 'Abandon',
      click: () => ask.myHelp.abandon(),
    });
  }

  if (ask.project?.canManage && ask.myHelp?.inReview) {
    nextBestActions.push({
      label: 'Mark as Done',
      click: () => ask.myHelp.approve(),
    });
    // nextBestActions.push({
    //   label: 'Send Back',
    //   click: () => ask.myHelp.sendBack(),
    // });
  }

  if (ask.project.userPermissions.includes('manage_asks')) {
    nextBestActions.push({
      label: 'Edit',
      click: () => {
        editAsk();
      },
    });
    nextBestActions.push({
      label: 'Delete',
      click: () => {
        deleteAsk();
      },
    });
  }
  const makeButton = nba => (
    <Button
      id="caret"
      color={nba.color || 'primary'}
      className="actionbtn position-relative"
      onClick={nba.click}
    >
      {nba.label}
    </Button>
  );
  if (!isEmpty(nextBestActions)) {
    if (nextBestActions.length === 1) {
      actions.push(makeButton(nextBestActions[0]));
    }
    if (nextBestActions.length > 1) {
      actions.push(
        <UncontrolledDropdown className="position-relative" size="sm" group>
          {makeButton(nextBestActions[0])}
          <DropdownToggle split color="primary" />
          <DropdownMenu className="small">
            {map(tail(nextBestActions), (nba, i) => (
              <DropdownItem className="small" key={i} onClick={nba.click}>
                {' '}
                {nba.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }

  return (
    <>
      {/* {ask.myHelp?.rejected && ( */}
      {/*  <Badge className="mr-2" color="danger"> */}
      {/*    Work declined */}
      {/*  </Badge> */}
      {/* )} */}
      {/* {ask.myHelp?.inReview && ( */}
      {/*  <Badge className="mr-2" color="info"> */}
      {/*    In review */}
      {/*  </Badge> */}
      {/* )} */}
      {actions}
    </>
  );
};
AskActions.propTypes = {
  ask: PropTypes.instanceOf(Object).isRequired,
};

export default observer(AskActions);
