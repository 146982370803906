import React from 'react';

import { observer } from 'mobx-react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, map, startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import t from '../../t';
import useStore from '../../data/store';
import Player from '../modules/player';

const HelpableCard = ({ helpable }) => {
  const subscribedNeedIds = Array.from(
    helpable.myProjectHelper?.subscribedNeedIds || []
  );

  const formatValue = value => value;

  const getStatCards = () => {
    const stats = [];
    map(Object.keys(helpable.stats), (stat, i) =>
      stats.push(
        <Badge className="badge-light info h-auto mb-2 xx-small p-2" key={i}>
          <span>{t(`projects.labels.${stat}`)} : </span>
          {formatValue(helpable.stats[stat].total, stat)}
        </Badge>
      )
    );
    return stats;
  };

  const needs = () => {
    const seeAllNeeds = helpable.hasPermission('manage_needs');
    return helpable.needs.map((n, idx) => {
      const isSubscribed = seeAllNeeds || subscribedNeedIds.includes(n.id);
      return (
        <p key={idx}>
          <span>
            {isSubscribed && (
              <>
                <FontAwesomeIcon
                  id={`need-${idx}-joined`}
                  icon={faCheckSquare}
                  className="mr-1"
                />
                <UncontrolledTooltip target={`need-${idx}-joined`}>
                  {t('projects.tooltips.helping')}
                </UncontrolledTooltip>
              </>
            )}
            {startCase(n.title)}
          </span>
        </p>
      );
    });
  };

  const calculateNextProjectStatusDue = () => {
    //    if overdue , return the word overdue in red
    if (helpable.isActive === false) return '';

    const lastProjectStatus = isEmpty(
      helpable.lastProjectAnnouncement.created_at
    )
      ? helpable.createdAt
      : helpable.lastProjectAnnouncement.created_at;
    const nextProjectStatusDue = moment(lastProjectStatus).add(28, 'days');
    const today = moment();
    const daysUntilNextProjectStatus = nextProjectStatusDue.diff(today, 'days');
    if (daysUntilNextProjectStatus < 0) {
      return <span className="text-danger font-weight-bold">Overdue</span>;
    }
    return (
      <>
        {daysUntilNextProjectStatus} {t('projects.labels.days')}
      </>
    );
  };

  return (
    <Card className="project-card">
      <CardHeader>
        <CardTitle className="d-flex justify-content-between">
          <div className="d-flex justify-content-start align-items-center">
            <span className="mr-2">
              <FontAwesomeIcon icon="rocket" />
            </span>
            {helpable?.isProject
              ? t('projects.labels.project_summary')
              : t('account.labels.hub_summary')}
          </div>
          {helpable.hasPermission('manage_project') && (
            <div>
              <Link
                to={`/p/${helpable.id}/settings`}
                className="text-black-50 pt-1"
              >
                <FontAwesomeIcon icon="cog" />
              </Link>
            </div>
          )}
          {helpable.hasPermission('manage_account') && (
            <div>
              <Link to="/settings" className="text-black-50 pt-1">
                <FontAwesomeIcon icon="cog" />
              </Link>
            </div>
          )}
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-2">
        {(helpable.hasPermission('manage_helpers') ||
          helpable.hasPermission('manage_hub')) &&
          helpable.hasRequests && (
            <div className="mb-2 mt-1 text-center">
              <Link
                className="font-weight-bold text-danger"
                to={
                  helpable.isProject
                    ? `/p/${helpable.id}/settings?tab=4`
                    : '/settings?tab=4'
                }
              >
                {t('projects.labels.requests_to_join')}
              </Link>{' '}
            </div>
          )}
        <Player url={helpable.videoUrlToShow} controls="lite" />
        <div className="project-details mt-1 created-by">
          <span className="subtitle">{t('projects.labels.created_by')}:</span>
          <span className="subtitle text-dark">{helpable.createdBy.name}</span>
        </div>
        {helpable?.isProject && (
          <>
            <div className="project-details mt-1 project-status-announcement">
              <span className="subtitle">
                {t('projects.labels.last_project_status')}:
              </span>
              <span className="subtitle text-dark">
                {' '}
                {isEmpty(helpable.lastProjectAnnouncement) &&
                  t('projects.labels.none_yet')}
                {!isEmpty(helpable.lastProjectAnnouncement) && (
                  <>
                    <Link
                      to={`/p/${helpable.id}/announcements/${helpable.lastProjectAnnouncement.id}`}
                    >
                      {moment(
                        helpable.lastProjectAnnouncement.created_at
                      ).format('MMMM Do YYYY')}
                    </Link>
                  </>
                )}
              </span>
            </div>
            <div className="project-details mt-1 project-status-announcement">
              <span className="subtitle">
                {t('projects.labels.next_project_status_due')}:
              </span>
              <span className="subtitle text-dark">
                {' '}
                {calculateNextProjectStatusDue()}
              </span>
            </div>
          </>
        )}
        <div className="project-details mt-1 tour-project-stats stats">
          <span className="subtitle">{t('projects.labels.stats')}:</span>
          <div className="stats-badge">{getStatCards()}</div>
        </div>
        <div className="project-details mt-1">
          <span className="subtitle">
            {t('projects.labels.needs_help_with', {
              count: helpable.needs.length,
            })}
            :
          </span>
          <div className="pd-info">{needs()}</div>
        </div>
      </CardBody>
    </Card>
  );
};

HelpableCard.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(HelpableCard);
