import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Auth from '../shared/_auth';
import useStore from '../../data/store';

const AuthPage = () => {
  const { userStore } = useStore();
  const history = useHistory();

  useEffect(() => {
    if (userStore.userSignedIn) history.push('/');
  }, []);

  return (
    <div className="vertically-center justify-content-center">
      <div>
        <Card>
          <CardBody>
            <Auth />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default observer(AuthPage);
