import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Alert, Button } from 'reactstrap';
import { isEmpty, isEqual } from 'lodash';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import Player from '../modules/player';
import WrappedHTML from '../shared/_wrapped_html';
import HintedLabel from '../shared/_hinted_label';
import Projects from './projects';
import Spacer from '../layouts/spacer';
import t from '../../t';

const HubDashboard = ({ match }) => {
  const { projectStore } = useStore();
  const { account } = projectStore;

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(account)) projectStore.fetchCurrentAccount();
  }, []);

  if (isEqual(account?.status, 'pending')) {
    if (!account.canManage) return <Redirect to="/hub404" />; // Safe Check, should already be handled by backend

    return <Redirect to="/settings" />;
  }

  function ctaButtons(showHelp = false) {
    if (account.hasAccess) return;
    return (
      <div className="p-4 d-flex flex-wrap justify-content-center">
        {!account.hasAccessPrivate && (
          <>
            {showHelp && (
              <HintedLabel
                className="medium"
                helpTextKey="what_is_helpbuild"
                button
                video
              />
            )}
            <Button
              color="primary"
              size="xl"
              onClick={() =>
                history.push(
                  account.access === 'protected'
                    ? '/check-access-code'
                    : '/join_hub'
                )
              }
            >
              {t(`account.join.${account.access}`)}
            </Button>
          </>
        )}
        {account.hasAccessPrivate && (
          <Alert color="danger" className="text-center">
            {t('account.join.private')}
          </Alert>
        )}
      </div>
    );
  }

  function sectionTitle(title) {
    return (
      <div className="text-center">
        <hr />
        <h3>{title.toLowerCase()}</h3>
        <hr />
      </div>
    );
  }

  return (
    <>
      <Spacer space={1} />
      <div className="centered w-90 hub-landing-page">
        <div className="p-1 text-center">
          <h4>👋🏽 {t('account.welcome.title', { hub: account.title })}</h4>
          <h1>{account.title} Hub</h1>
          <Spacer space={1} />
          <h2 className="font-weight-light">{account.tagline}</h2>
        </div>
        <Spacer space={1} />
        {ctaButtons(true)}
        <Spacer space={1} />
        <div className="w-75 centered p-0">
          <Player url={account.videoUrl} controls="playOnly" muted={false} />
        </div>
        <Spacer space={3} />
        {sectionTitle('About this hub')}
        <div className="p-1 text-left">
          <WrappedHTML
            content={account.description}
            className="mt-1 hub-description centered w-75"
          />
        </div>
        {ctaButtons(false)}
        {!account.hasAccessPrivate && (
          <>
            <Spacer space={3} />
            {sectionTitle(t('account.projects.title'))}
            <Projects account={account} />
          </>
        )}
      </div>
    </>
  );
};
HubDashboard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};
export default observer(HubDashboard);
