import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import OtpInput from 'react-otp-input';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import t from '../../t';
import WrappedHTML from './_wrapped_html';
import User from '../../data/entities/user';

function AuthOTP({ onSuccess }) {
  const { userStore } = useStore();
  const user = userStore.newUser;
  const [otp, setOtp] = useState('');

  const handleVerification = code => {
    user.verifyOTP(code, onSuccess);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleVerification(otp);
    return false;
  };

  useEffect(() => {
    userStore.update({ authMode: 'verify' });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Verification Code</Label>
        <div className="otp-input">
          <OtpInput
            value={otp}
            onChange={o => {
              setOtp(o);
              if (o.length === 6) {
                handleVerification(o);
              }
            }}
            numInputs={6}
            renderSeparator={<span className="otp-separator" />}
            renderInput={props => <input {...props} />}
          />
        </div>
      </FormGroup>
      <Button type="submit" color="primary" className="mt-3">
        Continue
      </Button>
    </form>
  );
}

AuthOTP.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default observer(AuthOTP);
