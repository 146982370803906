import { Alert, Button, Col, FormGroup, Input, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { find, isEmpty, map, reject } from 'lodash';
import { Country } from 'country-state-city';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import RichEditor from '../modules/rich_text_editor';
import {
  basicEditorToolbar,
  friendlyHelpableType,
} from '../../helpers/shared_helpers';
import HintedLabel from './_hinted_label';
import EditProjectNeeds from '../helpables/_project_needs';
import VideoUploaderWithUrl from './_video_uploader_with_url';
import t from '../../t';
import BuyTokens from './buy_tokens';
import ProjectNeedComponent from '../helpables/_project_need';
import { filterSelectStyles } from '../../helpers/sidebar_helpers';
import ProjectNeed from '../../data/entities/project_need';

const BasicProjectForm = ({ helpable, handleChange, full = true }) => {
  const countryOptions = [
    { label: 'Virtual', value: 'virtual' },
    ...Country.getAllCountries().map(country => ({
      label: country.name,
      value: country.name,
    })),
  ];

  const handleSeoImageUpload = e => {
    if (e.target.files) {
      helpable.update({
        seoImage: e.target.files[0],
      });
    }
  };

  const handleRemoveSeoImage = () => {
    helpable.update({
      seoImage: null,
      removeSeoImage: true,
      seoImageUrl: '',
      seoImageFilename: '',
    });
  };

  return (
    <div className="tour-project-info pl-3">
      {!isEmpty(helpable.errors) && full && (
        <Alert color="info">
          <strong>
            {t(`help.setup.pending`, {
              type: friendlyHelpableType(helpable.helpableType),
            })}
          </strong>
          <ul className="mt-2">
            {map(helpable.errors, (error, key) => (
              <li key={key}>{t(`help.${helpable.errors[key]}.error`)}</li>
            ))}
          </ul>
        </Alert>
      )}
      {helpable.isActive &&
        !isEmpty(helpable.wallet) &&
        helpable?.wallet.balance === 0 && (
          <Alert color="danger" className="text-center">
            {t('common.wallet.empty')}
            <BuyTokens
              className="ml-3 btn-xs"
              color="info"
              wallet={helpable.wallet}
            />
          </Alert>
        )}

      <Row>
        <Col lg={helpable.persisted ? 4 : 12}>
          <FormGroup>
            <HintedLabel
              helpTextKey={`${helpable.helpableType}_title`}
              error={helpable.hasError(`${helpable.helpableType}_title`)}
            />
            <Input
              value={helpable.title}
              onChange={handleChange}
              name="title"
            />
          </FormGroup>
        </Col>
        <Col lg={8}>
          <FormGroup>
            <HintedLabel helpTextKey={`${helpable.helpableType}_tagline`} />
            <Input
              value={helpable.tagline}
              onChange={handleChange}
              name="tagline"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        {helpable.persisted && (
          <Col lg={12}>
            <FormGroup>
              <div className="d-flex justify-content-between">
                <HintedLabel
                  helpTextKey="attach_video"
                  error={helpable.hasError('attach_video')}
                />
                <HintedLabel
                  className="medium font-weight-bolder"
                  helpTextKey="create_video"
                  title="How to create a compelling video"
                  video
                />
              </div>
              <VideoUploaderWithUrl
                attachable={helpable}
                removeAttachment={() => helpable.removeAttachment()}
              />
            </FormGroup>
          </Col>
        )}
        <Col lg={12}>
          <FormGroup
            className={classnames({
              'height-full': helpable.isProject && helpable.persisted,
            })}
          >
            <HintedLabel helpTextKey={`${helpable.helpableType}_description`} />
            <RichEditor
              placeholder="What is it about, why is it important and why should people support it?"
              onChange={m => helpable.update({ description: m })}
              value={helpable.description}
              toolbar={basicEditorToolbar}
              className="text-cta-editor"
            />
          </FormGroup>
        </Col>
      </Row>
      {full && helpable.isProject && (
        <Row>
          <Col lg={12}>
            <EditProjectNeeds
              className="mt-4 tour-edit-needs"
              project={helpable}
            />
          </Col>
        </Row>
      )}
      {full && !helpable.isProject && (
        <>
          <FormGroup className="mt-3">
            <HintedLabel helpTextKey="project_needs" />
            <div className="add-need">
              {helpable.projectNeeds.length === 0 && (
                <div className="text-muted">{t('common.no_needs')}</div>
              )}
              {helpable.projectNeeds.map((need, index) => (
                <ProjectNeedComponent need={need} key={index} />
              ))}
            </div>
          </FormGroup>
        </>
      )}

      {full && (
        <>
          <FormGroup>
            <HintedLabel helpTextKey="country" />
            <div>
              <Select
                options={countryOptions}
                styles={filterSelectStyles}
                placeholder={`Search or select which country this ${
                  helpable.isProject ? 'project' : 'hub'
                } is primarily based`}
                value={countryOptions.find(
                  option => option.value === helpable.country
                )}
                name="country"
                onChange={selectedOption =>
                  handleChange({
                    target: { name: 'country', value: selectedOption.value },
                  })
                }
              />
            </div>
          </FormGroup>
          <FormGroup className="image-uploader-group mt-3">
            <HintedLabel helpTextKey="seo_image" />
            <div className="image-uploader">
              <div className="image-uploader-thumbnail">
                <div>
                  {!isEmpty(helpable.seoImagePath) ? (
                    <img src={helpable.seoImagePath} alt="seo" />
                  ) : (
                    <FontAwesomeIcon icon={faImage} />
                  )}
                </div>
                {!isEmpty(helpable.seoImagePath) && (
                  <Button
                    className="remove-image"
                    onClick={handleRemoveSeoImage}
                    color="danger"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </div>
              <div className="image-uploader-control">
                <div className="flex-column d-flex" style={{ gap: 10 }}>
                  <Button color="primary">Attach</Button>
                </div>
                <div>{helpable.seoFilename}</div>
                <Input
                  accept=".png, .jpg, .jpeg"
                  onChange={handleSeoImageUpload}
                  type="file"
                />
              </div>
            </div>
          </FormGroup>
        </>
      )}
    </div>
  );
};

BasicProjectForm.propTypes = {
  helpable: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  full: PropTypes.bool,
};

export default observer(BasicProjectForm);
