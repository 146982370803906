import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import LayoutBody from '../_layout_body';

const AuthenticatedLayout = ({ children, loading }) => (
  <div className="authenticated-layout">
    <LayoutBody forcePublic={false} loading={loading}>
      {children}
    </LayoutBody>
  </div>
);

AuthenticatedLayout.defaultProps = {
  loading: false,
};

AuthenticatedLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default observer(AuthenticatedLayout);
