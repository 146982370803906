import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  includes,
  isEmpty,
  filter,
  map,
  pull,
  startCase,
  toLower,
} from 'lodash';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ProjectInfo from './_project_info';
import t from '../../t';

import { redirectToAuth } from '../../routes/AuthenticatedRoute';
import useStore from '../../data/store';
import HintedLabel from '../shared/_hinted_label';
import WrappedHTML from '../shared/_wrapped_html';
import SystemNeedsPicker from './_needs';
import SubscribedNeeds from './_subscribed_needs';
import Signup from '../shared/_auth';
import AuthModal from '../shared/_auth_modal';

const PublicView = ({ helpable, preview }) => {
  const { projectStore, userStore, appStore } = useStore();
  const [signup, setSignup] = useState(false);

  const [selectedNeeds, setSelectedNeeds] = useState(
    helpable?.myProjectHelper?.subscribedNeedIds || []
  );
  const [qualifyMessage, setQualifyMessage] = useState(
    helpable?.myProjectHelper?.qualifyingStatement
  );

  const toggleSignup = () => setSignup(!signup);

  const toggleNeed = needId => {
    if (helpable.amIHelping) return;
    if (!userStore.userSignedIn) {
      confirmAlert({
        title: 'Sign In / Sign Up',
        message: t(
          'You need to sign in/ sign up to join the project and choose areas to help with. Do you want to sign in/ sign up now?'
        ),

        buttons: [
          { label: t('common.no') },
          { label: t('btn_signin_to_help'), onClick: redirectToAuth },
        ],
      });
    }
    if (preview || !userStore.userSignedIn) return;
    const needs = [...selectedNeeds];

    if (includes(needs, needId)) {
      pull(needs, needId);
    } else {
      needs.push(needId);
    }

    setSelectedNeeds(needs);
  };

  const handleSave = () => {
    if (preview) return;

    if (isEmpty(selectedNeeds))
      return helpable.notifyError(
        'Please select at least one category of help.'
      );

    if (isEmpty(qualifyMessage))
      return helpable.notifyError(
        'Please fill a qualifying message. How do you think you might add value?'
      );

    helpable
      .join({
        project_needs: selectedNeeds,
        qualifying_statement: qualifyMessage,
      })
      .then(res => {
        if (!res) return helpable.notifyError('Something went wrong');

        projectStore.fetch().then(() => {
          projectStore.updateActiveProject(helpable.id);
        });
      });
  };

  const selectableNeeds = helpable?.isProject
    ? helpable.projectNeeds
    : appStore.systemProjectNeeds;

  if (isEmpty(helpable)) return null;

  const selectedNeedObjects = filter(selectableNeeds, need =>
    selectedNeeds.includes(need.id)
  );

  return (
    <div className="project-public-view">
      {userStore.userSignedIn &&
        helpable.amIHelping &&
        !helpable.myHelper.isConfirmed && (
          <Alert
            className="text-center"
            color={helpable.myHelper.isDeclined ? 'danger' : 'warning'}
          >
            <WrappedHTML
              content={t(
                `${helpable.helpableType}.helpers.request.${
                  helpable.myHelper.isDeclined ? 'declined' : 'requested'
                }`
              )}
            />
          </Alert>
        )}
      <div className={preview ? 'preview' : 'container'}>
        <Row className="flex-wrap">
          <Col lg={8}>
            <ProjectInfo helpable={helpable} preview={preview} />
          </Col>
          <Col lg={4}>
            <div className="sticky-section">
              <Badge className="pt-2  btn-block bg-white ">
                <HintedLabel
                  className="medium-large"
                  helpTextKey="what_is_helpbuild"
                  video
                />
              </Badge>

              <Card className="h-auto join-project-form">
                <CardBody>
                  <div>
                    <h5 className="text-center">
                      {!isEmpty(userStore.currentUser) && (
                        <>
                          Hi, {userStore.currentUser.firstName}
                          <br />
                        </>
                      )}
                      What can you help with?
                    </h5>
                    {!userStore.userSignedIn && (
                      <Button
                        onClick={toggleSignup}
                        color="primary mt-2 w-100 mb-3"
                      >
                        Sign In / Sign Up To Help
                      </Button>
                    )}
                    <SystemNeedsPicker
                      selected={selectedNeeds}
                      helpable={helpable}
                      toggle={toggleNeed}
                    />
                    {!isEmpty(selectedNeedObjects) && (
                      <SubscribedNeeds
                        title="Your chosen areas:"
                        toggle={toggleNeed}
                        canToggle={!helpable?.amIHelping}
                        needs={selectedNeedObjects}
                      />
                    )}
                    <div className="form-group mb-0">
                      {!(
                        helpable.amIHelping && helpable.myHelper.isBuilder
                      ) && (
                        <Input
                          disabled={
                            !userStore.userSignedIn ||
                            helpable.amIHelping ||
                            preview
                          }
                          className="qualify-message"
                          type="textarea"
                          rows={5}
                          placeholder={t(
                            `${helpable.helpableType}.join.helper.contribution`
                          )}
                          value={qualifyMessage}
                          onChange={e => setQualifyMessage(e.target.value)}
                        />
                      )}
                      {userStore.userSignedIn ? (
                        <>
                          {!helpable.amIHelping && (
                            <Button
                              onClick={handleSave}
                              color="primary mt-4 w-100"
                            >
                              {t('btn_help_on_this')}
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={toggleSignup}
                            color="primary mt-4 w-100"
                          >
                            {t('btn_signin_to_help')}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      <AuthModal isOpen={signup} toggle={toggleSignup} />
    </div>
  );
};

PublicView.defaultProps = {
  preview: false,
};

PublicView.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
  preview: PropTypes.bool,
};

export default observer(PublicView);
