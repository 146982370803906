import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import AsksIndex from '../asks';
import useStore from '../../data/store';
import { redirectToAuth } from '../../routes/AuthenticatedRoute';

const HubAsks = () => {
  const { userStore, projectStore } = useStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    projectStore.updateActiveProject(projectStore.account.id, 'account');

    setLoading(false);
  }, []);

  if (!userStore.userSignedIn) {
    redirectToAuth();
    return null;
  }

  if (loading) return null;

  return (
    <div>
      <ThreeColumnLayout
        center={<AsksIndex hub />}
        right={<HelpersLeaderboard />}
      />
    </div>
  );
};

export default observer(HubAsks);
