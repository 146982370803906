import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import AskInfo from './_ask_info';
import useStore from '../../data/store';
import AskDetail from './_ask_detail';
import ThreeColumnLayout from '../layouts/authenticate/three_column';

const AskDetailPage = ({ match }) => {
  const { askStore, projectStore } = useStore();
  const [ask, setAsk] = useState();

  useEffect(() => {
    askStore.fetchAsk(match.params?.askId).then(a => {
      if (a?.fetched) {
        const project = projectStore.getById(a.helpable?.id);
        projectStore.updateActiveProject(
          project?.id || '',
          isEmpty(project) ? '' : 'project'
        );

        setAsk(a);
      }
    });
  }, []);

  if (isEmpty(ask)) return null;

  return (
    <ThreeColumnLayout
      helpable={ask.helpable}
      center={<AskDetail extraClassNames="tour-ask-detail-card" ask={ask} />}
      right={<AskInfo what="statusSummary" ask={ask} />}
    />
  );
};

AskDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default observer(AskDetailPage);
