import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { isEmpty, map } from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import useStore from '../../data/store';
import Spacer from '../layouts/spacer';
import Player from '../modules/player';

function Discover() {
  const { discoverHubStore, projectStore } = useStore();

  useEffect(() => {
    if (projectStore.account.isEcosystem) {
      if (!discoverHubStore.fetching || !discoverHubStore.fetched) {
        discoverHubStore.fetch();
      }
    }
  }, [projectStore.account.isEcosystem]);

  if (isEmpty(projectStore.account)) return null;

  return (
    <div>
      <Spacer space={1} />
      <div className="centered w-90 hub-landing-page">
        <div className="p-1 text-center">
          <h4 className="x-small font-weight-light">
            Succeed with the right ecosystem
          </h4>
          <h1>Discover Hubs</h1>
          <Spacer space={1} />
          <p className="medium">
            Find the right ecosystem from all the great hubs designed to help
            projects succeed.
            <br />
            Join the <strong>The Helpbuild Ecosystem</strong> if you cannot find
            any other that suits your needs.
          </p>
        </div>
      </div>
      <Spacer space={3} />
      <div className="text-center">
        <hr />
        <h3>Available Public & Protected Hubs</h3>
        <hr />
      </div>

      <div>
        <Row className="mt-3 justify-content-center">
          {map([projectStore.account, ...discoverHubStore.records], hub => (
            <Col xl={4} lg={6} md={6}>
              <Card className="p-0 pb-3 mr-3" key={hub.id}>
                <CardBody>
                  <Badge
                    className="badge-pill xx-small font-weight-light"
                    color="info"
                  >
                    {hub.visibility}
                  </Badge>
                  <Player
                    url={hub.videoUrl}
                    controls="playOnly"
                    muted={false}
                  />
                </CardBody>
                <CardHeader>
                  <CardTitle className="small text-center">
                    <a target="_blank" href={hub.url} rel="noreferrer">
                      <strong>{hub.title}</strong>
                    </a>
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default observer(Discover);
