import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Button, FormGroup, Input, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useStore from '../../data/store';

function AuthMissingUserDetails({ onUpdate }) {
  const { userStore } = useStore();
  const [params, setParams] = useState({
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    userStore.update({ authMode: 'complete_profile' });
  }, []);

  const handleNameSubmit = e => {
    e.preventDefault();
    if (isEmpty(params.firstName) || isEmpty(params.lastName)) return;

    userStore.currentUser
      .updateServer(
        {
          first_name: params.firstName,
          last_name: params.lastName,
        },
        onUpdate
      )
      .then(res => {
        console.log('user updated');
      });
  };

  const handleCurrentUserChange = e => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleNameSubmit}>
      <FormGroup>
        <Label>First Name</Label>
        <Input
          value={userStore.currentUser.firstName}
          onChange={handleCurrentUserChange}
          name="firstName"
        />
      </FormGroup>
      <FormGroup>
        <Label>Last Name</Label>
        <Input
          value={userStore.currentUser.lastName}
          onChange={handleCurrentUserChange}
          name="lastName"
        />
      </FormGroup>
      <Button
        type="submit"
        disabled={isEmpty(params.firstName) || isEmpty(params.lastName)}
        color="primary"
      >
        Submit
      </Button>
    </form>
  );
}

AuthMissingUserDetails.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default observer(AuthMissingUserDetails);
