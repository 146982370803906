import React from 'react';

import { observer } from 'mobx-react';
import { Alert } from 'reactstrap';
import ThreeColumnLayout from '../layouts/authenticate/three_column';
import HelpersLeaderboard from '../helpers/_helper_leaderboard';
import AnnouncementsFeed from './_feed';
import withProject from '../layouts/with_project';
import useStore from '../../data/store';
import t from '../../t';
import WrappedHTML from '../shared/_wrapped_html';

const ActivitiesPage = () => {
  const { projectStore } = useStore();

  return (
    <div>
      {projectStore.activeProject.isUpdatePending && (
        <Alert color="danger" className="small text-center">
          <WrappedHTML
            content={t('projects.project_updates.pending_notification')}
          />
        </Alert>
      )}
      <ThreeColumnLayout
        center={<AnnouncementsFeed />}
        right={<HelpersLeaderboard />}
      />
    </div>
  );
};

export default withProject(observer(ActivitiesPage));
