import { action, computed, flow, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import BaseStore from './base_store';
import client from '../axiosClient';
import Account from '../entities/account';

class AppStore extends BaseStore {
  /* eslint-disable */
  @observable sidebarOpened = false //window.innerWidth > 991;
  @observable generalOnBoardingID = '';
  @observable minOrganisationFunding = 100;
  @observable creatable = null;
  @observable showVideoHelp = false;
  @observable videoHelpKey = 'defaultVideoHelpKey';
  @observable isProduction = false;
  @observable isBeta = false;
  @observable version = '';
  @observable purchaseTokens = false;
  @observable stripeProductKey = false;
  @observable stripePublishableKey = false;
  @observable systemProjectNeeds = [];
  @observable fetchedSystemNeeds = false;
  @observable baseUrl = 'helpbuild.co'  //window.location.hostname;
  @observable ecosystemHub = 'app'  //window.location.hostname;
  @observable accessCode = '';
  @observable accessCodeVerified = false;

maximumDesktopSize = 1400;
  /* eslint-enable */

  constructor(store) {
    super(store, null);

    makeObservable(this);
    this.fetchConfigFromServer();
    if (localStorage.getItem('hb_access_token')) {
      this.accessCode = localStorage.getItem('hb_access_token');
      this.checkAccessCode();
    }
  }

  @action
  isEcosystemHub() {
    //   if the subdomain is the on that represents the ecosystem hub
    return this.ecosystemHub === window.location.hostname.split('.')[0];
  }

  @action
  ecosystemHubUrl() {
    return `${window.location.protocol}//${this.ecosystemHub}.${this.baseUrl}`;
  }

  @computed
  get systemNeedOptions() {
    return this.systemProjectNeeds.map(need => ({
      label: need.title,
      value: need.id,
      description: need.description,
    }));
  }

  @flow
  *fetchConfigFromServer() {
    const response = yield client.get('/api/v1/app/configuration.json');
    if (response.data.success) {
      this.update(response.data.config);
    }
  }

  @flow
  *checkAccessCode() {
    yield client
      .post(`/api/v1/helpables/verify_access`, {
        access_code: this.accessCode,
      })
      .then(res => {
        if (res.data.success) {
          localStorage.setItem('hb_access_token', this.accessCode);
          this.accessCodeVerified = true;
          return true;
        }

        localStorage.removeItem('hb_access_token');
        toast.error('Access code not valid');
        return false;
      });
  }

  @flow
  *fetchSystemNeeds() {
    if (this.fetchedSystemNeeds) return;

    const response = yield client.get('/api/v1/app/system_needs.json');

    this.update({
      systemProjectNeeds: response.data.needs,
    });

    this.update({ fetchedSystemNeeds: true });
  }

  @action
  initiateCreatable(c) {
    this.creatable = c;
  }

  @action
  toggleCreatable() {
    this.creatable = null;
  }
}

export default AppStore;
