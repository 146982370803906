import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import PublicView from '../helpables/_public_view';

const JoinWrapper = ({ match, helpableType }) => {
  const { projectStore, appStore, userStore } = useStore();
  const [helpable, setHelpable] = useState(null);
  const [effectCompleted, setEffectCompleted] = useState(false);
  const { account } = projectStore;
  const { currentUser } = userStore;
  const location = useLocation();
  const shareId = new URLSearchParams(location.search).get('share_id');

  useEffect(() => {
    if (isEmpty(account || effectCompleted)) return;
    if (
      helpableType !== 'project' &&
      !account.hasAccess &&
      !account.hasAccessPublic &&
      !appStore.accessCodeVerified
    ) {
      setEffectCompleted(true);
      return;
    }
    if (shareId) localStorage.setItem('_user_share_id', shareId);

    projectStore
      .fetchHelpable(helpableType, match?.params.id, shareId)
      .then(res => {
        if (res.success) {
          setHelpable(res.helpable);
        }
        setEffectCompleted(true);
      });
  }, [shareId, account]);

  if (!effectCompleted) return <div>Loading...</div>;

  if (helpableType === 'project') {
    if (
      !isEmpty(helpable) &&
      (!helpable.isPending || helpable.canManage || currentUser.isHubAdmin)
    ) {
      return <PublicView helpable={helpable} />;
    }
    // else  redirect to project home
    return <Redirect to="/" />;
  }
  console.log(
    'Only hubs should get here - Checking if we have access or it is private'
  );

  if (account.hasAccess) {
    return <PublicView helpable={helpable} />;
  }

  console.log(
    'Only hubs that have access conditions should get here get here - Checking if we have access or it is private'
  );

  if (!account.hasAccess) {
    if (account.hasAccessPrivate) {
      return <Redirect to="/" />;
    }
    if (account.hasAccessProtected && !appStore.accessCodeVerified) {
      return (
        <Redirect
          to={{
            pathname: '/check-access-code',
            state: { redirectUrl: `/join?share_id=${shareId || ''}` },
          }}
        />
      );
    }
  }

  return <PublicView helpable={helpable} />;
};

JoinWrapper.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  helpableType: PropTypes.string,
};

export default observer(JoinWrapper);
