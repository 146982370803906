import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import HubForm from '../hubs/hub_form';
import t from '../../t';
import Auth from '../shared/_auth';

function StartHub() {
  return (
    <div className="min-vertically-center">
      <Row className="align-items-center">
        <Col lg={5}>
          <div>
            <h1>{t(`onboard.heading.hub_only`)}</h1>
            <p>{t(`onboard.subheading.hub_only`)}</p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="">
            <Auth
              afterAuthComponent={HubForm}
              inModal={false}
              afterAuthMode="complete_hub_create"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default observer(StartHub);
