import { action, computed, flow, makeObservable, observable } from 'mobx';
import { isEqual } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import BaseEntity from './base_entity';

class ActionedAsk extends BaseEntity {
  /* eslint-disable */
  @observable projectHelperId;
  @observable projectAskId;
  @observable status;
  @observable ask;
  @observable friendlyName;
  @observable walletId;
  @observable helper;
  /* eslint-enable */

  constructor(value, store, ask) {
    super(value, store);

    this.ask = ask;

    makeObservable(this);

    this.handleConstruction(value);
  }

  @computed
  get project() {
    return this.ask.project;
  }

  @computed
  get statusClass() {
    switch (this.status) {
      case 'todo':
        return 'success';
      case 'doing':
        return 'warning';
      case 'review':
        return 'info';
      case 'done':
        return 'success';
      case 'rejected':
        return 'danger';
      case 'abandoned':
        return 'danger';
      case 'blocked':
        return 'danger';
      default:
        return 'info';
    }
  }

  @computed
  get helping() {
    return isEqual(this.status, 'todo');
  }

  @computed
  get rejected() {
    return isEqual(this.status, 'rejected');
  }

  @computed
  get inReview() {
    return isEqual(this.status, 'review');
  }

  @computed
  get completed() {
    return isEqual(this.status, 'done');
  }

  get inProgress() {
    return isEqual(this.status, 'doing');
  }

  get abandoned() {
    return isEqual(this.status, 'abandoned');
  }

  @computed
  get projectHelper() {
    return this.helper;
  }

  @computed
  get helperUserId() {
    return this.projectHelper?.userId;
  }

  @action
  handleConstruction(value) {
    const val = { ...value };

    this.initialize(val);
  }

  @flow
  *updateStatus(status) {
    const response = yield this.client.post(
      `/api/v1/asks/${this.askId}/ask_helper_status_update.json`,
      { status }
    );

    if (response.data.success) {
      this.ask.handleConstruction(response.data.ask);
    }

    return response.data.success;
  }

  @flow
  *start() {
    const response = yield this.client.put(
      `/api/v1/asks/${this.projectAskId}/start.json`
    );

    if (response.data.success) {
      this.ask.handleConstruction(response.data.ask);

      this.project.updateWallet('token', response.data.token_balance);
      this.project.update({ stats: response.data.project_stats });

      return true;
    }

    if (response.data.message) this.notifyError(response.data.message);

    return false;
  }

  @flow
  *review() {
    const response = yield this.client.put(
      `/api/v1/asks/${this.projectAskId}/review.json`
    );

    if (response.data.success) {
      this.ask.handleConstruction(response.data.ask);
    }

    return response.data.success;
  }

  @action
  abandon() {
    confirmAlert({
      title: 'Abandon',
      message:
        'Abandoning this ask will result in losing all progress and it cannot be undone.',
      buttons: [
        {
          label: 'Abandon',
          onClick: () => {
            this.client
              .put(`/api/v1/asks/${this.projectAskId}/abandon.json`)
              .then(response => {
                if (response.data.success) {
                  this.ask.handleConstruction(response.data.ask);

                  this.project.updateWallet(
                    'token',
                    response.data.token_balance
                  );
                  this.project.update({ stats: response.data.project_stats });
                }

                return response.data.success;
              });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  }

  @flow
  *approve() {
    const response = yield this.client.post(
      `/api/v1/asks/${this.projectAskId}/approve.json`,
      { actioned_ask_id: this.id }
    );

    if (response.data.success) {
      this.ask.handleConstruction(response.data.ask);

      this.project.updateWallet('token', response.data.token_balance);
      this.project.update({ stats: response.data.project_stats });
    }

    return response.data.success;
  }

  @flow
  *decline() {
    const response = yield this.client.post(
      `/api/v1/asks/${this.projectAskId}/decline.json`,
      { actioned_ask_id: this.id }
    );

    if (response.data.success) {
      this.ask.handleConstruction(response.data.ask);
      this.project.updateWallet('token', response.data.token_balance);
      this.project.update({ stats: response.data.project_stats });
    }

    return response.data.success;
  }
}

export default ActionedAsk;
