import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { filter, find, includes, isEmpty, map } from 'lodash';

import moment from 'moment';
import ActionedAsk from './actioned_ask';
import BaseEntity from './base_entity';
import t from '../../t';
import Commentable from '../concerns/commentable';
import Helpable from './helpable';

class Ask extends BaseEntity {
  /* eslint-disable */
  @observable title = '';
  @observable description = '';
  // asks automatically expire 14 days after creation by default
  @observable expiresAt = new Date(Date.now() + 2 * 7 * 24 * 60 * 60 * 1000);
  @observable maxHelpers = 1;
  @observable projectNeed = {};
  @observable projectHelper = {};
  @observable status = '';
  @observable acceptanceConditions = '';
  @observable projectId = '';
  @observable rewardAmount = 1;
  @observable rewardType = 'token';
  @observable actionedAsks = [];
  @observable moreDetails = false;
  @observable fetched = true;
  @observable fetchedError = false;
  @observable publishStatus = 'published';
  @observable helpable;

  createdAt;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.handleConstruction(value);

    extendObservable(this, Commentable);
  }

  @computed
  get project() {
    return this.store.rootStore.projectStore.activeProject;
  }

  @computed
  get acceptedHelpers() {
    return filter(this.workingHelpers, { status: 'accepted' });
  }

  @computed
  get statusOptions() {
    return [
      { label: 'Draft', value: 'draft' },
      { label: 'Published', value: 'published' },
    ];
  }

  @computed
  get reviewHelpers() {
    return filter(this.workingHelpers, { status: 'review' });
  }

  @computed
  get globalStatus() {
    return this.status;
  }

  @computed
  get statusClass() {
    return t(`ask.status.classes.${this.globalStatus}`);
  }

  @computed
  get projectHelpers() {
    return map(this.actionedAsks, pha => pha.projectHelper);
  }

  @computed
  get owner() {
    return this.store.rootStore.projectHelperStore.getById(
      this.projectHelperId
    );
  }

  @computed
  get canManage() {
    return this.project.canManageAsks;
  }

  @computed
  get amIHelping() {
    return !isEmpty(this.myHelp);
  }

  @computed
  get myHelp() {
    return find(this.actionedAsks, {
      helper: {
        user_id: this.currentUser?.id,
      },
    });
  }

  @computed
  get acceptable() {
    return (
      !this.isExpired &&
      !this.canManage &&
      this.maxHelpers > this.workingHelpers.length &&
      !this.amIHelping
    );
  }

  @computed
  get startable() {
    return this.maxHelpers > this.workingHelpers.length;
  }

  @computed
  get workingHelpers() {
    return filter(
      this.actionedAsks,
      a =>
        !includes(
          ['todo', 'abandoned', 'unaccepted', 'blocked', 'ignored'],
          a.status
        )
    );
  }

  @computed
  get isExpired() {
    return !isEmpty(this.expiresAt) && moment(this.expiresAt) < moment();
  }

  @action
  handleConstruction(value, fetched = false) {
    const val = { ...value };

    if (val.messages) {
      map(val.messages, msg => {
        this.store.rootStore.messageStore.addRecord({
          ...msg,
          messageableId: value.id,
          messageableType: 'Ask',
        });
      });

      delete val.messages;
    }

    if (val.expires_at) {
      this.expiresAt = moment(val.expires_at);

      delete val.expires_at;
    }

    if (val.actioned_asks) {
      this.actionedAsks = map(
        val.actioned_asks,
        pha => new ActionedAsk(pha, this.store, this)
      );

      delete val.actioned_asks;
    }

    if (fetched) {
      this.fetched = true;
    }

    if (val.helpable) {
      this.helpable = new Helpable(
        val.helpable,
        this.store.rootStore.projectStore
      );
      delete val.helpable;
    }

    this.initialize(val);
  }

  sendParams() {
    return {
      title: this.title,
      expires_at: this.expiresAt,
      description: this.description,
      max_helpers: this.maxHelpers,
      project_id: this.projectId,
      category_id: this.projectNeed.id,
      reward_type: this.rewardType,
      reward_amount: this.rewardAmount,
      acceptance_conditions: this.acceptanceConditions,
      status: this.publishStatus,
    };
  }

  @flow
  *create() {
    const response = yield this.client.post(
      `/api/v1/asks.json`,
      this.sendParams()
    );

    if (response.data.success) {
      this.store.addRecord(response.data.ask);
      this.store.notifySuccess('Ask has been created successfully');

      return true;
    }

    return false;
  }

  @flow
  *save() {
    const response = yield this.client.put(
      `/api/v1/asks/${this.id}.json`,
      this.sendParams()
    );

    if (response.data.success) {
      this.store.notifySuccess('Ask has been updated successfully');

      return true;
    }

    return false;
  }

  @flow
  *join() {
    const response = yield this.client.post(
      `/api/v1/asks/${this.id}/join.json`
    );

    if (response.data.success) {
      return this.handleConstruction(response.data.ask);
    }
    this.notifyError(response.data.message);
  }

  @flow
  *delete() {
    const response = yield this.client.delete(`/api/v1/asks/${this.id}`);

    if (response.data.success) {
      this.destroy();
      return true;
    }
    this.notifyError(response.data.message);
  }

  @flow
  *fetch() {
    if (this.fetched) return;

    const response = yield this.client.get(`/api/v1/asks/${this.id}`);

    if (response.data.success) {
      return this.handleConstruction(response.data.ask, true);
    }

    this.update({ fetchedError: true });
  }
}

export default Ask;
