import React from 'react';

import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStore from '../data/store';
import AuthenticatedLayout from '../views/layouts/authenticate';
import ProjectRequestLayout from '../views/layouts/project_request';
import AuthModal from '../views/shared/_auth_modal';
import AuthPage from '../views/auth';

export const redirectToAuth = store => {
  localStorage.setItem(
    'redirectURL',
    `${window.location.pathname}${window.location.search}`
  );
  store.showAuthModal = true;
  return <AuthModal mode="signin" />;
};

const AuthenticatedRoute = ({ component, path }) => {
  const { userStore } = useStore();

  // if (!userStore.userSignedIn) return redirectToAuth(userStore);

  return (
    <AuthenticatedLayout>
      <ProjectRequestLayout>
        {!userStore.userSignedIn && redirectToAuth(userStore)}
        {userStore.userSignedIn && (
          <Route exact path={path} component={component} />
        )}
      </ProjectRequestLayout>
    </AuthenticatedLayout>
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.object,
  path: PropTypes.string,
};

export default observer(AuthenticatedRoute);
