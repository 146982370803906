import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Label,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTimeAgo from 'react-time-ago';
import Player from '../modules/player';
import useStore from '../../data/store';
import Comments from '../shared/comments';
import AnnouncementBox from './_announcement_box';
import t from '../../t';

function Announcement({ announcement, hyperlink, basic }) {
  const history = useHistory();
  const { projectStore, userStore } = useStore();

  return (
    <>
      <Card
        className="mb-3 cursor-pointer h-auto announcement-card"
        onClick={
          !hyperlink
            ? null
            : () =>
                history.push(
                  `/p/${projectStore.activeProject.id}/announcements/${announcement.id}`
                )
        }
      >
        <CardHeader>
          <CardTitle>
            <div className="mb-2">
              {announcement.status === 'draft' && (
                <Badge color="warning" className="need-badge">
                  {t('common.status.draft')}
                </Badge>
              )}
              <Badge
                className="need-badge"
                color={announcement.announcementTypeBadgeColor}
              >
                <span className="small">
                  {announcement.announcementTypeTitle}
                </span>
              </Badge>
            </div>
            {announcement.subject}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {!isEmpty(announcement.videoUrl) && (
            <div className="mb-3">
              <Player url={announcement.videoUrl} />
            </div>
          )}
          <div
            className="small"
            dangerouslySetInnerHTML={{ __html: announcement.body }}
          />
        </CardBody>
        <CardFooter className="small p-3">
          <div className="ask-footer">
            <div className="ask-footer-left">
              <Label>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={{ prefix: 'far', iconName: 'calendar' }}
                />
                {t('common.created')}{' '}
                <ReactTimeAgo date={new Date(announcement.createdAt)} />
              </Label>
            </div>
            {!basic && userStore.userSignedIn && (
              <div className="ask-footer-right">
                <AnnouncementBox
                  project={projectStore.activeProject}
                  onAnnouncement={updatedJson => {
                    announcement.update(updatedJson);
                    announcement.update({ json: updatedJson });
                  }}
                  record={announcement}
                  buttonTitle={t('common.edit')}
                />
              </div>
            )}
          </div>
        </CardFooter>
      </Card>
      {!basic && <Comments commentable={announcement} />}
    </>
  );
}

Announcement.defaultProps = {
  hyperlink: false,
  basic: false,
};

Announcement.propTypes = {
  announcement: PropTypes.instanceOf(Object).isRequired,
  hyperlink: PropTypes.bool,
  basic: PropTypes.bool,
};

export default observer(Announcement);
