import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Redirect, useLocation } from 'react-router-dom';
import useStore from '../../data/store';
import t from '../../t';
import WrappedHTML from '../shared/_wrapped_html';

function CheckAccessCode() {
  const { appStore } = useStore();
  const location = useLocation();

  if (appStore.accessCodeVerified)
    return <Redirect to={location.state?.redirectUrl || '/join_hub'} />;

  const submitHandler = e => {
    e?.preventDefault();
    appStore.checkAccessCode().then(res => {
      setRedirect(res);
    });
  };

  return (
    <div>
      <Row className="vertically-center">
        <Col lg={{ size: 6, offset: 3 }}>
          <WrappedHTML
            className="text-center mb-4"
            content={t('onboard.protected_hub')}
          />
          <Card>
            <CardBody>
              <form onSubmit={submitHandler}>
                <FormGroup>
                  <Label>Access Code</Label>
                  <Input
                    autoFocus
                    value={appStore.appStore}
                    onChange={e =>
                      appStore.update({ accessCode: e.target.value })
                    }
                  />
                </FormGroup>
                <Button color="primary" onClick={submitHandler}>
                  Verify Access
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default observer(CheckAccessCode);
