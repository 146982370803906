import { action, computed, flow, makeObservable, observable } from 'mobx';
import { capitalize, filter, find, isEmpty, isEqual, map } from 'lodash';

import { useLocation } from 'react-router-dom';
import BaseStore from './base_store';
import Helpable from '../entities/helpable';

class HelpableStore extends BaseStore {
  /* eslint-disable */
  @observable defaultNeeds = [];
  @observable defaultCapacities = [];
  @observable defaultProjectTypes = [];
  @observable fetchedDefaultData = false;
  @observable creatable = null;
  @observable activeProjectId = '';
  @observable activeHelpableType = 'project';
  @observable account = {};

  /* eslint-enable */

  constructor(store) {
    super(store, Helpable);
    makeObservable(this);
  }

  @action
  updateActiveProject(projectId, type = 'project') {
    if (!isEmpty(projectId) && !isEqual(projectId, 'undefined')) {
      this.update({
        activeProjectId: projectId,
        activeHelpableType: type,
      });
    }
  }

  @computed
  get isInProjectView() {
    const location = useLocation();
    return (
      location.pathname.includes('/p/') ||
      (location.pathname.startsWith('/asks') &&
        this.activeHelpableType === 'project')
    ); // && this.hasActiveProject;
  }

  @computed
  get allNeedsAsSelectOptions() {
    if (this.hasActiveProject) return this.activeProject.needsAsSelectOptions;

    let needs = [];

    map(this.activatedProjects, p => {
      needs = [...needs, ...p.needsAsSelectOptions];
    });

    return needs;
  }

  @computed
  get activeProject() {
    if (this.activeHelpableType === 'account') return this.account;

    if (isEmpty(this.records)) {
      return null;
    }
    if (isEmpty(this.activeProjectId)) {
      this.setDefaultActiveProject();
    }

    // go get the active project from the backend and put in the records

    return find(this.records, { id: this.activeProjectId }) || null;
  }

  @computed
  get currentUserSubscribedProjectNeeds() {
    const { subscribedNeedIds } = this.activeProject.myProjectHelper;
    const seesAllNeeds = this.hasPermission('manage_needs');
    return map(
      filter(
        this.activeProject.projectNeeds,
        r => seesAllNeeds || subscribedNeedIds.includes(r.id)
      ),
      r => ({
        label: capitalize(r.title),
        value: r.id,
      })
    );
  }

  @computed
  get activeProjectForEdit() {
    return this.activeProject;
  }

  setDefaultActiveProject() {
    if (isEmpty(this.records)) return;

    if (!isEmpty(this.rootStore.userStore.currentUser?.recentProject)) {
      this.updateActiveProject(
        this.rootStore.userStore.currentUser?.recentProject
      );
      return;
    }

    this.updateActiveProject(this.records[0]?.id);
  }

  @computed
  get activeProjectAsSelectOption() {
    if (isEmpty(this.activeProject)) return null;
    return { label: this.activeProject.title, value: this.activeProject.id };
  }

  @computed
  get hasActiveProject() {
    return !isEmpty(this.activeProject);
  }

  @computed
  get helpers() {
    return this.hasActiveProject
      ? this.activeProject.projectHelpers
      : this.rootStore.projectHelperStore.records;
  }

  @computed
  get recordsAsSelectOptions() {
    return this.recordsAsSelectOptionsWithoutGlobal;
  }

  @computed
  get sortedProjects() {
    return this.records.slice().sort((a, b) => {
      if (a.title < b.title) return -1;

      if (a.title > b.title) return 1;

      return 0;
    });
  }

  @action
  hasPermission(permission) {
    if (isEmpty(permission)) return true;

    return [...this.activeProject.userPermissions].includes(permission);
  }

  @computed
  get recordsAsSelectOptionsWithoutGlobal() {
    return map(this.sortedProjects, r => ({ label: r.title, value: r.id }));
  }

  @action
  deselectAll() {
    this.records.map(r => r.update({ selected: false }));
  }

  @action
  initializeNewProject(history = null) {
    this.update({
      newProject: new Helpable(
        {
          id: 'new',
          title: 'Untitled',
          description: '',
          reason: '',
          size: this.rootStore.appStore.minimumProjectSize,
          selected: true,
          status: 'pending',
        },
        this
      ),
    });

    if (history) history.push('/project/new');
  }

  @flow
  *fetch(onFetch) {
    if (this.fetching) return;
    this.update({ fetching: true });
    const response = yield this.client.get(`/api/v1/helpables.json`);

    this.update({
      records: map(response.data.projects, project => this.addRecord(project)),
      fetched: true,
      fetching: false,
    });

    if (onFetch) {
      onFetch();
    }
  }

  @flow
  *fetchDefaultReferences() {
    if (this.fetchedDefaultData) return;

    const response = yield this.client('/api/v1/references.json');

    this.update({
      defaultNeeds: response.data.needs,
      defaultCapacities: response.data.capacities,
      defaultProjectTypes: response.data.project_types,
      fetchedDefaultData: true,
    });
  }

  @flow
  *fetchHelpable(type = 'project', id, shareId = '') {
    const response = yield this.client.get(`/api/v1/helpables/${id}`, {
      params: {
        share_id: shareId,
        helpable_type: type,
      },
    });
    if (response?.data?.helpable) {
      const helpable = new Helpable(response.data.helpable, this);
      return {
        success: true,
        helpable,
      };
    }

    return { success: false };
  }

  @action
  fetchCurrentAccount() {
    const subDomain = window.location.host.split('.')[0];
    if (isEmpty(subDomain)) return;

    return this.fetchHelpable('account', subDomain).then(res => {
      if (res.success) {
        this.account = res.helpable;
      }
    });
  }

  get subDomain() {
    return window.location.host.split('.')[0];
  }

  @flow
  *fetchCurrentAccountProjects() {
    const sd = this.subDomain;

    if (isEmpty(sd)) return;

    const response = yield this.client.get(`/api/v1/helpables.json`, {
      params: {
        hub: true,
      },
    });

    if (response?.data?.projects) {
      const projects = map(
        response.data.projects,
        helpable => new Helpable(helpable, this)
      );
      return {
        success: true,
        projects,
      };
    }
    return { success: false };
  }
}

export default HelpableStore;
