import { map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AskDetail from './_ask_detail';

const AsksFeed = ({ records, hub }) => (
  <div className="feed-view">
    {map(records, (record, i) => (
      <AskDetail
        extraClassNames={`${i === 0 ? 'tour-ask-card' : ''}`}
        ask={record}
        key={i}
        basic
        hub={hub}
      />
    ))}
  </div>
);
AsksFeed.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  hub: PropTypes.bool.isRequired,
};

export default observer(AsksFeed);
